body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #86868b;
}

.container {
    max-width: 800px;
    margin: 100px auto;
    margin-bottom: 0;
    padding: 20px;
    color: #86868b;
}

h1 {
    font-size: 4em;
    /* Vergroot de titel */
    margin-bottom: 40px;
    /* Meer ruimte tussen de titel en de tekst */
    color: #ffffff;
    font-weight: 700;
}

p {
    font-size: 1.5em;
    /* Grotere tekst */
    line-height: 2;
    /* Meer lijnafstand */
    margin-bottom: 20px;
}

.highlight {
    /* color: #ffffff; */
    font-weight: 500;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.custom-button {
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    padding: 9px 29px;
    padding-right: 9px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s ease;
}

.icon-container {
    background-color: #007aff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.btc {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center;
    font-size: .9rem;
    margin: 2rem 0 1rem 0;
    color: #86868b;
    transition-duration: 400ms;
}

.btc:hover {
    text-decoration: underline;
    transition-duration: 400ms;
    color: #FF9900;
}

@media (max-width: 600px) {
    h1 {
        font-size: 2.6em;
    }

    p {
        font-size: 1.2em;
    }

    .button-container {
        margin-top: 3rem;
    }

    .custom-button {
        padding: 9px 20px;
    }

    .icon-container {
        width: 30px;
        height: 30px;
    }
}